<template>
	<div class="cusListAdd-container">
		<el-drawer class="cusListAdd-drawer" :title="addType" :visible.sync="drawer" :append-to-body="true" direction="rtl"
		:before-close="handleClose">
			<div class="drawer-container">
				<el-form :model="validateForm" ref="validateForm" label-width="100px" class="demo-ruleForm">
					<el-form-item :label="addType == '新增平台' || addType == '编辑平台' ? '平台名称' : '类目名称'" prop="platformName" :rules="[{ required: true, message: '名称不能为空'},{ max: 15, message: '名称长度小于15 个字符'}]">
						<el-input v-model="validateForm.platformName"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" class="common-screen-btn" @click="submitForm('validateForm')">提交</el-button>
						<!-- <el-button type="primary" class="common-screen-btn" @click="submit()">提交</el-button> -->
					</el-form-item>
				</el-form>
			</div>
		</el-drawer>
	</div>
</template>
<script>
	import { createPlat, updatePlat, addEditcategory } from "../../../service/customer.js";
	export default {
		props: ["drawer", "addType"],
		data() {
			return {
				validateForm: {
					platformName: '' ,
					id: 0,
					parentId: 0
				}
			};
		},
		methods: {
			handleClose (done) {
				this.$emit("handleClose", done);
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.submit()
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			async submit() {
				let code;
				let addType = this.addType
				let validateForm = {...this.validateForm}
				if (addType == '新增平台') {
					delete validateForm.elementUuid
					code = (await createPlat(validateForm)).code;
				} else if (addType == '编辑平台') {
					delete validateForm.parentId
					code = (await updatePlat(validateForm)).code;
				} else {
					delete validateForm.parentId
					delete validateForm.elementUuid
					code = (await addEditcategory(validateForm)).code;
				}
				if ( code == 200 ) {
					this.$message.success("提交成功")
					this.$emit("platformList")
					this.$emit("handleClose");
				}
			},
			setPlatName (name, pId, id, elementUuid) {
				this.validateForm.platformName = name
				this.validateForm.parentId = pId
				this.validateForm.id = id
				this.validateForm.elementUuid = elementUuid
				// if (this.$refs["validateForm"]) this.$refs["validateForm"].resetFields();
			}
		},
	};
</script>
<style lang="less" scoped>
	.cusListAdd-drawer {
		.upload-icon-input {
			width: 88px;
			height: 40px;
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			cursor: pointer;
		}

		.drawer-container {
			padding: 0 24px;
			text-align: left;
		}

		.upload-icon-container {
			display: inline-block;
			position: relative;
			width: 88px;
			height: 40px;

			.common-screen-btn {
				margin: 0;
			}
		}

		// /deep/ .el-drawer__open .el-drawer.rtl {
		//   width: 38% !important;
		// }
	}
</style>
